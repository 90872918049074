import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Modal } from 'antd';

const SurveysPanel = ({
  surveys, handleDisableSurvey, handleSelectSurvey, handleCopySurvey,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [surveyCopyName, setSurveyCopyName] = useState('');
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const handleOk = () => {
    handleCopySurvey(selectedSurvey, surveyCopyName);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleShowModal = (surveyId) => {
    setShowModal(true);
    setSelectedSurvey(surveyId);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      render: (actions) => (
        <>
          <Button
            style={{ marginRight: 5 }}
            onClick={() => handleSelectSurvey(actions.id)}
          >
            Ver
          </Button>
          <Button danger onClick={() => handleDisableSurvey(actions.id)}>
            Eliminar
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleShowModal(actions.id)}
          >
            copiar
          </Button>
        </>
      ),
    },
  ];
  const dataSource = surveys.map((s) => ({
    ...s,
    key: s.id,
    actions: { deleted: s.deleted, id: s.id },
  }));

  return (
    <div>
      <Modal
        title="Copiar encuesta"
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Nombre de la nueva encuesta</p>
        <input
          value={surveyCopyName}
          onChange={(e) => setSurveyCopyName(e.target.value)}
        />
      </Modal>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

SurveysPanel.propTypes = {
  surveys: PropTypes.array,
  handleSelectSurvey: PropTypes.func,
  handleDisableSurvey: PropTypes.func,
  handleCopySurvey: PropTypes.func,
};

export default SurveysPanel;
