import {
  SET_ASSIGNMENTS,
  SET_AVAILABLE_LOCATIONS,
  REMOVE_ASSIGNMENT, CLEAR_ASSIGNMENTS,
} from './types';
import { actionHandler } from '../../utils/actionHandler';
import { executeAction } from '../../utils/app/app.actions';

export const setAssignments = (assignments) => ({
  type: SET_ASSIGNMENTS,
  payload: { assignments },
});

export const getAssignments = (token) => (dispatch) => {
  actionHandler('/assignments', 'get', token, null, dispatch, setAssignments);
};

export const getAssignmentsQuery = (token,query) => (dispatch) => {
  actionHandler(`/assignments?${query}`, 'get', token, null, dispatch, setAssignments);
};

export const createAssignment = (assignment, token) => async (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getAssignments(token));
  actionHandler('/assignments', 'post', token, assignment, dispatch, cb);
};

export const removeAssignment = (id) => ({
  type: REMOVE_ASSIGNMENT,
  payload: { id },
});

export const deleteAssignment = (id, token) => async (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(removeAssignment(id));
  actionHandler(`/assignments/${id}`, 'delete', token, null, dispatch, cb);
};

export const setAvailableLocations = (locations) => ({
  type: SET_AVAILABLE_LOCATIONS,
  payload: { locations },
});

export const getAvailableLocationsForSurvey = (surveyId, token) => (
  dispatch,
) => {
  const cb = (locations) => (innerDispatch) => innerDispatch(setAvailableLocations(locations));
  actionHandler(
    `/assignments/available-locations/${surveyId}`,
    'get',
    token,
    null,
    dispatch,
    cb,
  );
};

export const getFinishedAssignments = (token, query) => (dispatch) => {
  const cb = (assignments) => (innerDispatch) => innerDispatch(setAssignments(assignments));
  actionHandler(
    `/assignments?active=true&deleted=false&finished=true&inProgress=false${query || ''}`,
    'get',
    token,
    null,
    dispatch,
    cb,
  );
};

export const rejectFinishedAssignment = (assignmentId, token) => (dispatch) => {
  const cb = () => (innerDispatch) => innerDispatch(getFinishedAssignments(token));
  actionHandler(
    `/assignments/${assignmentId}`,
    'patch',
    token,
    { inProgress: true },
    dispatch,
    cb,
  );
};

export const clearAssignments = () => (dispatch) => {
  dispatch(executeAction(CLEAR_ASSIGNMENTS));
};
