import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Collapse, Card, Button, Space, Modal, Tooltip,
} from 'antd';
import FontSizeOutlined from '@ant-design/icons/lib/icons/FontSizeOutlined';
import NumberOutlined from '@ant-design/icons/lib/icons/NumberOutlined';
import { LinkOutlined } from '@material-ui/icons';
import QuestionForm from './QuestionForm';
import {
  getSurveyQuestions,
  getSurveys,
  createSurveyQuestion,
  deleteSurveyQuestion,
  getSurveyOptions,
  deleteOption,
  createOption, setConditionalQuestion, unLinkConditionalQuestion,
} from '../../store/surveys/actions';
import PageLayout from '../../components/Layout/PageLayout';
import { editQuestion } from '../../store/tags/tags.actions';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import { useHasErrors } from '../../utils/app/error/error.hooks';
import { actionTypes } from '../../store/tags/tags.types';
import { SET_CONDITION, UNLINK_CONDITION } from '../../store/surveys/types';
import ConditionQuestionModal from './ConditionQuestionModal';

const Survey = ({
  token,
  surveys,
  selectedSurveyQuestions,
  onGetSurveys,
  onGetSurveyQuestions,
  onCreateSurveyQuestion,
  onDeleteSurveyQuestion,
  onDeleteOption,
  match,
  options,
  onGetSurveyOptions,
  onCreateOption,
}) => {
  const [newOption, setNewOption] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [selectedOption, setSelectedOption] = useState(0);
  const [editedValue, setEditedValue] = useState('');
  const [showeditModal, setShowEditModal] = useState(false);
  const [showConditionModal, setShowConditionModal] = useState(false);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [nextForOption, setNextForOption] = useState(0);

  const [editIsLoading, editIsFinished] = useIsLoading([actionTypes.EDIT_QUESTION]);
  const [editError, editHasError] = useHasErrors([actionTypes.EDIT_QUESTION]);

  const [conditionIsLoading, conditionIsFinished] = useIsLoading([SET_CONDITION]);
  const [conditionError, conditionHasError] = useHasErrors([SET_CONDITION]);

  const [unLinkIsLoading, unLinkIsFinished] = useIsLoading([UNLINK_CONDITION]);
  const [unLinkError, unLinkHasError] = useHasErrors([UNLINK_CONDITION]);

  const surveyId = Number(match.params.surveyId);
  const survey = surveys.find((s) => s.id === surveyId) || {};
  const dispatch = useDispatch();

  const handleCreateQuestion = (data, clearForm) => {
    const bulkOpts = !data.bulkOptions || data.bulkOptions === ''
      ? []
      : data.bulkOptions.split('\n').map((opt) => ({
        text: opt,
        optionType: data.optionType || 0,
        single: data.single,
      }));
    const singleOptsArr = data.options
      ? data.options.map((opt) => ({
        text: opt,
        optionType: data.optionType || 0,
        single: data.single,
      }))
      : [];
    const formatOptions = [...singleOptsArr, ...bulkOpts];
    const question = {
      options: formatOptions,
      position: 1,
      text: data.description,
      type: data.type,
      single: data.single,
    };
    onCreateSurveyQuestion(surveyId, question, token);
    clearForm();
    window.scrollTo(0, 0);
  };

  const handleDeleteQuestion = (questionId) => {
    onDeleteSurveyQuestion(surveyId, questionId, token);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showeditModal);
  };

  const toggleConditionsModal = (question) => {
    setSelectedQuestion(question);
    setShowConditionsModal(!showConditionsModal);
  };

  const toggleConditionModal = (question, option) => {
    setSelectedQuestion(question);
    setSelectedOption(option);
    setShowConditionModal(!showConditionModal);
  };

  const handleEditQuestion = (question) => {
    setSelectedQuestion(question);
    setEditedValue(question.text);
    toggleEditModal();
  };

  const editQuestionText = () => {
    const text = editedValue;
    const questionId = selectedQuestion.id;
    dispatch(editQuestion({ text, surveyId, questionId }));

    toggleEditModal();
  };

  const handleCondition = () => {
    dispatch(setConditionalQuestion({
      surveyId, questionId: selectedQuestion.id, optionId: selectedOption, conditionId: nextForOption,
    }));
    setShowConditionModal(!showConditionModal);
  };

  const unlinkQuestion = (question, option, condition) => {
    dispatch(unLinkConditionalQuestion({
      surveyId, questionId: question, optionId: option, conditionId: condition,
    }));
    setShowConditionsModal(!showConditionsModal);
  };

  useEffect(() => {
    onGetSurveys(token);
    onGetSurveyQuestions(surveyId, token);
    onGetSurveyOptions(token);
  }, [onGetSurveys, onGetSurveyQuestions, surveyId, token, onGetSurveyOptions]);

  useEffect(() => {
    if (editIsFinished) {
      if (!editHasError) {
        onGetSurveyQuestions(surveyId, token);
      }
    }
  }, [editIsLoading, editHasError]);

  useEffect(() => {
    if (conditionIsFinished) {
      if (!conditionHasError) {
        onGetSurveyQuestions(surveyId, token);
      }
    }
  }, [conditionIsLoading, conditionHasError]);

  useEffect(() => {
    if (unLinkIsFinished) {
      if (!unLinkHasError) {
        onGetSurveyQuestions(surveyId, token);
      }
    }
  }, [unLinkIsLoading, unLinkHasError]);

  const surveyQuestionList = (surveyQuestions) => (
    <div>
      {surveyQuestions.length ? (
        <Collapse ghost>
          {surveyQuestions
            .filter((q) => !q.deleted)
            .map((q) => (
              <Collapse.Panel header={q.text} key={q.id}>
                <Card
                  style={{ width: 600 }}
                  title={`Tipo de respuesta: ${q.type}`}
                  extra={(
                    <Space>
                      <Button
                        onClick={() => handleDeleteQuestion(q.id)}
                      >
                        Eliminar Pregunta
                      </Button>
                      <Button
                        type="dashed"
                        onClick={() => handleEditQuestion(q)}
                      >
                        Editar Pregunta
                      </Button>
                      {q.conditions && q.conditions.length > 0
                                    && (
                                    <Tooltip placement="right" title="Ver condicionados" onClick={() => toggleConditionsModal(q)}>
                                      <LinkOutlined />
                                    </Tooltip>
                                    )}

                    </Space>
                              )}
                >
                  {q.type !== 'photo'
                            && (
                            <>
                              <p>
                                { q.single ? (
                                  <>
                                    <b style={{ color: 'red' }}>*</b>
                                    Unica Respuesta
                                    {' '}
                                    <br />
                                    {' '}
                                  </>
                                ) : ''}
                                <b style={{ fontSize: 16 }}>Opciones: </b>
                              </p>
                              <div style={{ marginBottom: 10 }}>
                                {q.type !== 'photo' && <p>Agregar nueva opcion</p>}
                                <input value={newOption} onChange={(e) => setNewOption(e.target.value)} />
                                <Button
                                  style={{ marginLeft: 5 }}
                                  disabled={!newOption}
                                  onClick={() => {
                                    onCreateOption(surveyId, q.id, newOption, token);
                                    setNewOption('');
                                  }}
                                >
                                  agregar
                                </Button>
                              </div>
                              {q.options.map((opt) => (
                                <div style={{ display: 'flex' }}>
                                  <p key={opt.id}>{opt.text}</p>
                                  {opt.next
                                        && (
                                        <Tooltip placement="right" title={opt.next}>
                                          <LinkOutlined />
                                        </Tooltip>
                                        )}
                                  <div style={{ flex: 1 }} />
                                  <Button
                                    danger
                                    onClick={() => onDeleteOption(survey.id, q.id, opt.id, token)}
                                  >
                                    eliminar
                                  </Button>
                                  {opt.next
                                    ? (
                                      <Button
                                        danger
                                        onClick={() => unlinkQuestion(q.id, opt.id, opt.nextId)}
                                      >
                                        Desvincular
                                      </Button>
                                    )
                                    : (
                                      <Button
                                        type="dashed"
                                        onClick={() => toggleConditionModal(q, opt.id)}
                                      >
                                        Condicionar
                                      </Button>
                                    )}

                                  <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    {opt.type === 0 ? <FontSizeOutlined /> : <NumberOutlined /> }
                                  </div>
                                </div>
                              ))}
                            </>
                            )}
                  {q.type === 'photo'
                            && (
                            <>
                              <p>
                                <b style={{ fontSize: 16 }}>Descripcion: </b>
                              </p>
                              {q.options.map((opt) => (
                                <div style={{ display: 'flex' }}>
                                  <p key={opt.id}>{opt.text}</p>
                                </div>
                              ))}
                            </>
                            )}
                </Card>
              </Collapse.Panel>
            ))}
        </Collapse>
      ) : (
        <div>No tienes preguntas todavia</div>
      )}
    </div>
  );

  return (
    <PageLayout
      sider={survey ? (
        <div>
          <div style={{ marginBottom: 10 }}>
            <b style={{ fontSize: 16 }}>Nueva pregunta</b>
          </div>

          <QuestionForm
            handleCreateQuestion={handleCreateQuestion}
            options={options}
          />
        </div>
      ) : null}
      title={`${survey.id}. ${survey.name}`}
    >
      <div>
        {survey && (
          <div style={{ display: 'flex' }}>
            <div>
              <b style={{ fontSize: 16 }}>Descripción: </b>
              <p>{survey.description}</p>
            </div>
          </div>
        )}
        {survey && (
          <div style={{ marginTop: 10 }}>
            <div>
              <b style={{ fontSize: 16 }}>Preguntas: </b>
            </div>
            {surveyQuestionList(selectedSurveyQuestions.filter((q) => !q.conditional))}
          </div>
        )}
        {survey
        && (
        <div style={{ marginTop: 10 }}>
          <div>
            <b style={{ fontSize: 16 }}>Preguntas En Condicion: </b>
            {surveyQuestionList(selectedSurveyQuestions.filter((q) => q.conditional))}
          </div>
        </div>
        )}
      </div>
      <Modal
        title="Editar Pregunta"
        visible={showeditModal}
        onOk={editQuestionText}
        onCancel={toggleEditModal}
      >
        <p>Editar la pregunta</p>
        <input
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          style={{ width: '100%' }}
        />
      </Modal>
      <Modal
        title="Condicionar respuesta"
        visible={showConditionModal}
        onOk={handleCondition}
        onCancel={toggleConditionModal}
      >
        <ConditionQuestionModal questions={selectedSurveyQuestions} surveyId={surveyId} question={selectedQuestion.id} setNext={setNextForOption} />

      </Modal>

      <Modal
        title="Preguntas condicionadas"
        visible={showConditionsModal}
        onOk={toggleConditionsModal}
        onCancel={toggleConditionsModal}
        width={800}
      >
        {selectedQuestion && selectedQuestion.conditions && (
        <Space direction="vertical" size={16}>
          {selectedQuestion.conditions.map((condition) => (
            <Card title={condition.condition} extra={<Button danger onClick={() => unlinkQuestion(condition.questionId, condition.optionId, condition.conditionId)}>Desvincular</Button>} style={{ width: 700 }}>
              <p>
                Opcion:
                <b>{condition.option}</b>
              </p>
            </Card>
          ))}
        </Space>
        )}
      </Modal>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.currentUser.token,
  surveys: state.surveys.data,
  selectedSurveyQuestions: state.surveys.selectedSurveyQuestions,
  options: state.surveys.options,
});

const mapDispatchToProps = {
  onGetSurveys: getSurveys,
  onGetSurveyQuestions: getSurveyQuestions,
  onCreateSurveyQuestion: createSurveyQuestion,
  onDeleteSurveyQuestion: deleteSurveyQuestion,
  onGetSurveyOptions: getSurveyOptions,
  onDeleteOption: deleteOption,
  onCreateOption: createOption,
};

Survey.propTypes = {
  token: PropTypes.string,
  surveys: PropTypes.array,
  selectedSurveyQuestions: PropTypes.array,
  onGetSurveys: PropTypes.func,
  onGetSurveyQuestions: PropTypes.func,
  onCreateSurveyQuestion: PropTypes.func,
  onDeleteSurveyQuestion: PropTypes.func,
  match: PropTypes.object,
  onGetSurveyOptions: PropTypes.func,
  options: PropTypes.array,
  onDeleteOption: PropTypes.func,
  onCreateOption: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Survey));
