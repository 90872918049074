import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Form, List, Option, Row, Select, Steps, Modal,
} from 'antd';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import CloseCircleTwoTone from '@ant-design/icons/lib/icons/CloseCircleTwoTone';
import { selectSurveys } from '../../store/clients/clients.selector';
import { assignByList, getItemsForTagList, getTags } from '../../store/tags/tags.actions';
import { getSurveys } from '../../store/surveys/actions';
import { selectCurrentToken } from '../../store/auth/auth.selector';
import { selectItemsOnResultList, selectItemsOnTagList, selectTags } from '../../store/tags/tags.selector';
import { useHasErrors } from '../../utils/app/error/error.hooks';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import { actionTypes } from '../../store/tags/tags.types';

const AsignmentSteps = (props) => {
  const { Step } = Steps;
  const dispatch = useDispatch();

  const surveys = useSelector(selectSurveys);
  const token = useSelector(selectCurrentToken);
  const tags = useSelector(selectTags);
  const itemsOnTagList = useSelector(selectItemsOnTagList);
  const resultList = useSelector(selectItemsOnResultList);

  const [current, setCurrent] = useState(0);

  const [selectedSurvey, setSelectedSurvey] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [requestUsers, setRequestUsers] = useState([]);
  const [requestLocations, setRequestLocations] = useState([]);

  const [getAssignIsLoading, getAssignIsFinished] = useIsLoading([actionTypes.ASSIGN_BY_LIST]);
  const [getAssignError, getAssignHasError] = useHasErrors([actionTypes.ASSIGN_BY_LIST]);

  const [getUsersLoading, getUsersIsFinished] = useIsLoading([actionTypes.GET_ITEMS_FOR_TAG_LIST_0]);

  const [getLocationsIsLoading, getLocationsIsFinished] = useIsLoading([actionTypes.GET_ITEMS_FOR_TAG_LIST_1]);

  const next = () => {
    if (current + 1 === 1) {
      dispatch(getItemsForTagList({ items: selectedUsers, type: 0 }));
      dispatch(getItemsForTagList({ items: selectedLocations, type: 1 }));
    }
    if (current + 1 === 2) {
      console.log('to Next step');
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleChange = (value) => {
    console.log(value);
    const found = surveys.filter((s) => s.id === value);
    setSelectedSurvey(found ? found[0] : {});
  };

  const handleUserTags = (value) => {
    setSelectedUsers(value);
  };

  const handleLocationTags = (value) => {
    setSelectedLocations(value);
  };

  const init = () => {
    dispatch(getSurveys(token));
    dispatch(getTags());
  };

  const removeLocation = (id) => {
    const removed = requestLocations.filter((u) => u.id !== id);
    console.log(removed, id);
    setRequestLocations([...removed]);
  };

  const removeUser = (id) => {
    const removed = requestUsers.filter((u) => u.id !== id);
    console.log(removed, id);
    setRequestUsers([...removed]);
  };

  useEffect(() => {
    if (getAssignIsFinished) {
      if (!getAssignHasError && resultList.errors.length == 0) {
        Modal.success({
          content: 'Asignacion realizada con exito',
          onOk: props.toggleModal(),
        });
      } else {
        Modal.error({
          title: 'Error',
          width: '800px',
          content: <>
            Error al asignar con etiquetas
            <div>
              {resultList && resultList.errors.length > 0 ? resultList.errors.map((e) => (
                <>
                  -
                  {e}
                </>
              )) : []}
            </div>
          </>,
        });
      }
    }
  }, [getAssignIsLoading, getAssignHasError]);

  useEffect(() => {
    if (getUsersIsFinished) {
      setRequestUsers([...itemsOnTagList.users]);
    }
  }, [getUsersLoading]);

  useEffect(() => {
    if (getLocationsIsFinished) {
      setRequestLocations([...itemsOnTagList.locations]);
    }
  }, [getLocationsIsLoading]);

  const steps = [
    {
      title: 'Seleccionar',
      content: <>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="surveys" label="Encuestas" rules={[{ required: true }]}>
            <Select
              options={surveys ? surveys.map((s) => ({ value: s.id, label: s.name })) : []}
              value={selectedSurvey}
              onChange={handleChange}
              allowClear
            />
          </Form.Item>
          <Form.Item name="userTags" label="Usuarios" rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              onChange={handleUserTags}
              options={tags ? tags.filter((t) => t.type === 'Usuarios').map((t) => ({ value: t.id, label: t.tagText })) : []}
            />
          </Form.Item>
          <Form.Item name="locationTags" label="Puntos de venta" rules={[{ required: true }]}>
            <Select
              mode="multiple"
              allowClear
              onChange={handleLocationTags}
              options={tags ? tags.filter((t) => t.type === 'Puntos de venta').map((t) => ({ value: t.id, label: t.tagText })) : []}
            />
          </Form.Item>
        </Form>
      </>,
    },
    {
      title: 'Resumen',
      content: <>
        <div>
          <h1>Resumen</h1>
          <p>
            La encuesta
            <b>
              {' '}
              { selectedSurvey && selectedSurvey.name ? selectedSurvey.name : '' }
              {' '}
            </b>
            {' '}
            Sera asignada a los siguientes usuarios en los siguientes puntos de venta:
            {' '}
          </p>
          <Row>
            <Col sm={12}>
              <List
                bordered
                style={{ height: '300px', overflow: 'scroll', margin: '10px' }}
                locale={{ emptyText: 'Sin elementos' }}
                dataSource={requestUsers}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{item.name}</div>
                    <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} onClick={() => removeUser(item.id)} />
                  </List.Item>
                )}
              />
            </Col>
            <Col sm={12}>
              <List
                bordered
                style={{ height: '300px', overflowY: 'scroll', margin: '10px' }}
                locale={{ emptyText: 'Sin elementos' }}
                dataSource={requestLocations}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {' '}
                      {item.name}
                    </div>
                    {' '}
                    <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} onClick={() => removeLocation(item.id)} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
      </>,
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const handleAssign = () => {
    console.log(itemsOnTagList);
    console.log(selectedSurvey.id, requestUsers.map((u) => u.id), requestLocations.map((l) => l.id));
    dispatch(assignByList({ surveyId: selectedSurvey.id, usersList: requestUsers.map((u) => u.id), locationsList: requestLocations.map((l) => l.id) }));
  };

  useEffect(init, []);
  return (
    <div>
      <Steps current={current} items={items} />
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
        <Button type="primary" onClick={() => next()}>
          Siguente
        </Button>
        )}
        {current === steps.length - 1 && (
        <Button type="primary" onClick={handleAssign}>
          Asignar
        </Button>
        )}
        {current > 0 && (
        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
          Anterior
        </Button>
        )}
      </div>
    </div>
  );
};

export default AsignmentSteps;
