import { $http } from '../../utils/http.utils'

class SurveysService {
    getConditionalQuestions =
        ({surveyId,question}) =>
            $http.get(`/surveys/${surveyId}/question/${question}/conditional`);

    setConditionalQuestion =
        ({surveyId,questionId, optionId, conditionId}) =>
            $http.post(`/surveys/${surveyId}/questions/${questionId}/options/${optionId}/condition/${conditionId}`);

    unLinkConditionalQuestion =
        ({surveyId,questionId, optionId, conditionId}) =>
            $http.post(`/surveys/${surveyId}/questions/${questionId}/options/${optionId}/unlink/${conditionId}`);
}
export default new SurveysService()
