import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Button, Table, Tag, Modal, Space, Transfer,
} from 'antd';
import TagsOutlined from '@ant-design/icons/lib/icons/TagsOutlined';
import {
  createUser,
  disableUser,
  getUsers,
  getRoles,
  addUserRole,
  removeUserRole,
  resetPassword,
} from '../../store/users/actions';
import UserForm from './UserForm';
import PageLayout from '../../components/Layout/PageLayout';
import TagSelector from '../../components/Tags/TagSelector';
import { addTagOnItem, getTagsForItem, removeTagOnItem } from '../../store/tags/tags.actions';
import { useIsLoading } from '../../utils/app/loading/loading.hooks';
import { actionTypes } from '../../store/tags/tags.types';
import { useHasErrors } from '../../utils/app/error/error.hooks';
import { selectItemTags, selectTags } from '../../store/tags/tags.selector';
import { selectCurrentUser } from '../../store/auth/auth.selector';

export const Users = ({
  users = [],
  onCreateUser,
  onDisableUser,
  onGetUsers,
  onGetRoles,
  token,
  userRoles = [],
  onAddUserRole,
  onRemoveUserRole,
  onResetPassword = () => {},
  user,
}) => {
  const success = (text, title) => {
    Modal.info({
      title,
      content: (
        <div>
          <p>{text}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const dispatch = useDispatch();
  const { error } = Modal;

  const tags = useSelector(selectTags);
  const itemTags = useSelector(selectItemTags);
  const selectorCurrentUser = useSelector(selectCurrentUser);

  const [getIsLoading, getIsFinished] = useIsLoading([actionTypes.GET_TAGS_FOR_ITEM]);
  const [getError, getHasError] = useHasErrors([actionTypes.GET_TAGS_FOR_ITEM]);

  // hooks
  const [tagModal, setTagModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [targetUsers, setTargetUsers] = useState([]);
  const [selectedKeyUsers, setSelectedKeyUsers] = useState([]);

  useEffect(() => {
    if (getIsFinished) {
      if (getHasError) {
        error({
          title: '¡Uh-oh!',
          content: getError.message || 'Ocurrio un error inesperado',
        });
      } else {
        setTargetUsers(itemTags.map((i) => i.id));
        setTagModal(true);
      }
    }
  }, [getIsLoading, getHasError]);

  const toggleTagModal = () => { setTagModal(!tagModal); };

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const onChangeUsers = (nextTargetKeys, direction, moveKeys) => {
    const operation = direction === 'right' ? 0 : 1;
    if (operation === 0) {
      console.log(nextTargetKeys);// tagId, itemId, type
      console.log(selectedItem);
      console.log(0);
      dispatch(addTagOnItem({ itemId: selectedItem, items: nextTargetKeys, type: 0 }));
    } else { // will delete Items
      console.log(moveKeys);// tagId, itemId, type
      console.log(selectedItem);
      console.log(0);
      dispatch(removeTagOnItem({ itemId: selectedItem, items: moveKeys, type: 0 }));
    }
    setTargetUsers(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeyUsers([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleResetPassword = (userId) => {
    onResetPassword(userId, token, success);
  };

  const handleCreateUser = (data, resetForm) => {
    const user = {
      email: data.email,
      name: data.name,
    };
    const role = {
      roleId: data.role,
    };
    onCreateUser(user, token, role, success);
    resetForm();
  };

  const handleDisableUser = (id) => {
    onDisableUser(id, token);
  };

  const handleAddUserRole = (userId, roleId) => {
    onAddUserRole(userId, { roleId }, token);
  };

  const handleRemoveUserRole = (userId, roleId) => {
    onRemoveUserRole(userId, { roleId }, token);
  };

  useEffect(() => {
    onGetUsers(token);
    onGetRoles(token);
  }, [onGetUsers, token, onGetRoles]);

  const cols = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Usuario',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles) => {
        const isAdmin = roles.data.find((el) => el.id === 1);
        const isPollster = roles.data.find((el) => el.id === 2);
        const isWebUser = roles.data.find((el) => el.id === 3);
        const adminColor = isAdmin ? 'green' : 'silver';
        const pullsertColor = isPollster ? 'green' : 'silver';
        const pullWebColor = isWebUser ? 'green' : 'silver';
        const onClickAdmin = isAdmin
          ? () => handleRemoveUserRole(roles.userId, 1)
          : () => handleAddUserRole(roles.userId, 1);
        const onClickPollster = isPollster
          ? () => handleRemoveUserRole(roles.userId, 2)
          : () => handleAddUserRole(roles.userId, 2);
        const onClickWebUser = isWebUser
          ? () => handleRemoveUserRole(roles.userId, 3)
          : () => handleAddUserRole(roles.userId, 3);
        return roles.userId !== 1 ? (
          <div>
            {user.roles.find((x) => x.id === 1)
              && (
              <Tag color={adminColor} onClick={onClickAdmin}>
                Admin
              </Tag>
              )}
            <Tag color={pullsertColor} onClick={onClickPollster}>
              Encuestador
            </Tag>
            <Tag color={pullWebColor} onClick={onClickWebUser}>
              Web
            </Tag>
          </div>
        ) : null;
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (action) => (action.id !== 1 ? (
        <>
          <Button
            type="primary"
            danger
            onClick={() => handleDisableUser(action.id)}
          >
            Eliminar
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleResetPassword(action.id)}
          >
            Resetear password
          </Button>
        </>
      ) : null),
    },
    {
      title: 'Etiquetas',
      dataIndex: 'action',
      key: 'key',
      render: (action) => (
        <Space direction="vertical" size="small">
          {
            selectorCurrentUser.roles.find((x) => x.id === 3) && (
            <Button onClick={() => { setSelectedItem(action.id); dispatch(getTagsForItem({ itemId: action.id, type: 0 })); }} icon={<TagsOutlined style={{ verticalAlign: 'middle' }} />} color="#55acee">
              Agregar
            </Button>
            )
          }
        </Space>
      ),
    },
  ];

  const userTable = users.filter((u) => !u.deleted).map((u) => ({
    ...u,
    key: u.id,
    action: {
      deleted: u.deleted,
      id: u.id,
    },
    roles: {
      data: u.roles,
      userId: u.id,
    },
  }));

  return (
    <PageLayout
      sider={
        <UserForm handleCreateUser={handleCreateUser} userRoles={userRoles} />
      }
      title="Usuarios"
    >
      <div className="Users">
        <div>
          <Table columns={cols} dataSource={userTable} />
        </div>
      </div>
      <Modal
        title="Editar Etiquetas"
        centered
        visible={tagModal}
        okText="Editar"
        onOk={toggleTagModal}
        onCancel={toggleTagModal}
        width={1000}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Transfer
            showSearch
            operations={['Agregar', 'Eliminar']}
            filterOption={filterOption}
            dataSource={tags ? tags.filter((t) => t.type === 'Usuarios').map((u) => ({ id: u.id, title: `${u.tagText}`, key: u.id })).sort((a, b) => (a.title < b.title ? -1 : 1)) : []}
            titles={['Disponibles', 'Actuales']}
            targetKeys={targetUsers}
            selectedKeys={selectedKeyUsers}
            onChange={onChangeUsers}
            onSelectChange={onSelectChange}
            render={(item) => item.title}
          />
        </div>
      </Modal>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.currentUser.token,
  users: state.users.data,
  userRoles: state.users.roles,
  user: state.auth.currentUser,
});

const mapDispatchToProps = {
  onGetUsers: getUsers,
  onCreateUser: createUser,
  onDisableUser: disableUser,
  onGetRoles: getRoles,
  onAddUserRole: addUserRole,
  onRemoveUserRole: removeUserRole,
  onResetPassword: resetPassword,
};

Users.propTypes = {
  users: PropTypes.array,
  token: PropTypes.string,
  onCreateUser: PropTypes.func,
  onDisableUser: PropTypes.func,
  onGetUsers: PropTypes.func,
  onGetRoles: PropTypes.func,
  userRoles: PropTypes.array,
  onAddUserRole: PropTypes.func,
  onRemoveUserRole: PropTypes.func,
  onResetPassword: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
